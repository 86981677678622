.card {
  padding: 10px;
  border-radius: 5%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 650px;
  margin: auto;
  text-align: center;
  background-color: rgba(255, 191, 95, 0.86)
}

.card img {
  padding: 15% 0 0 0;
  width: 75%
}

.disclaimer {
  font-size: x-small;
  font-weight: bold;
}

.description {
  font-weight:lighter;
}

.title {
  font-size: 30px;
  margin: 0px 0px -15px 0px
}

.sub-title {
  font-size: 15px;
}

button {
  border: none;
  border-radius: 30%;
  outline: 0;
  display: inline-block;
  padding: 8px;
  color: white;
  background-color: brown;
  text-align: center;
  cursor: pointer;
  width: 50%;
  font-size: 18px;
}

button:hover, a:hover {
  opacity: 0.7;
}



